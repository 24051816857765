import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import MainView from './views/main';
import Privacy from './views/privacy';
import Support from './views/support';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div className='main_container'>

          <Container className='content_container'>
            <Route exact path='/' component={MainView} />
            <Route exact path='/privacy-policy' component={Privacy} />
            <Route exact path='/support' component={Support} />
          </Container>

          <Container className='allns_footer'>
            <Row className='justify-content-center'>
              Copyright &copy; {`${(new Date()).getFullYear()} `}
              All Business Online News Group Ltd.
              All rights reserved. 
            </Row>
            <Row className='justify-content-center'>
              <Link to='/privacy-policy' className='privacy'>Privacy Policy</Link>
              <Link to='/support' className='support'>Support</Link>
            </Row>
          </Container>
        </div>
      </Router>
    );
  }
}

export default App;
